import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Container, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import Input from '../components/Core/Input';
import DatePicker, { registerLocale } from 'react-datepicker';
import { de, enUS } from 'date-fns/locale';
import Head from '../components/Head';
import CustomSelect from '../components/Core/Select';
import Button from '../components/Core/Button';
import Loader from '../components/Core/Loader';

import CreateJobPosition from '../components/CreateJob/CreateJobPosition';
import { initialJobPosition } from '../components/JobPositionForm';
import { bonusOptions } from '../components/Modal/NewJob';
import api from '../utils/api';
import { handleError } from '../utils/handleError';
import { labelRoles } from '../utils/constants';
import { createEvent } from '../actions/eventAction';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  getTimeWithOffset,
  endTimeEndsNextDay,
  getTimeDifferent,
} from '../utils/helper';
import { manageStartEndTime } from '../utils/time';
import EventFeePayment from '../components/Modal/EventFeePayment';

const initialJob = {
  jobName: '',
  jobDescription: '',
  jobRequirements: '',
  jobBonus: '',
  jobPositions: [{ ...initialJobPosition }],
};
registerLocale('de', de);
registerLocale('en', enUS);

const NewRequest = () => {
  const staffTypes = useSelector(
    (state) => state?.settings?.settingData?.staffTypes,
  );
  const fee = useSelector((state) => state?.settings?.settingData?.eventFee);

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [places, setPlaces] = useState([]);
  const [creatingRequest, setCreatingRequest] = useState(false);
  const [jobLoading, setJobLoading] = useState(true);
  const [jobPositions, setJobPositions] = useState([]);
  const { t, i18n } = useTranslation();
  const currentlanguage = localStorage.getItem('i18nextLng') ?? 'de';
  const [showFeeModal, setShowFeeModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const fetchJobs = async () => {
    try {
      let response = await api.get('/global-settings');
      let resJobs = response.data.settings[0].staffTypes;
      setJobLoading(false);
      const filteredJobs = resJobs.map((job) => ({
        label: job.name,
        value: job.name,
      }));
      setJobPositions(filteredJobs);
    } catch (e) {
      setJobLoading(false);
      handleError(e);
    }
  };

  React.useEffect(() => {
    function fetchMyAPI() {
      Promise.all([fetchJobs()]);
    }

    fetchMyAPI();
  }, []);

  const scrollToElement = (elName, isName = true) => {
    let el = '';
    if (isName) {
      el = document.querySelector(`input[name='${elName}']`);
    } else {
      el = document.querySelector(`label[for='${elName}']`);
    }
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  };
  const modules = {
    formats: [
      'bold',
      'italic',
      'underline',
      'strike',
      'link',
      'blockquote',
      'code',
      'header',
      'list',
      'image',
      'video',
    ],
    exclude: ['p'],
  };

  return (
    <>
      <Formik
        initialValues={{
          eventName: '',
          eventExpirationDate: '',
          jobs: [{ ...initialJob }],
        }}
        validationSchema={Yup.object({
          eventName: Yup.string().required(t('eventName')),
          eventExpirationDate: Yup.string().required(t('eventExpirationDate')),
          jobs: Yup.array().of(
            Yup.object().shape({
              jobName: Yup.object().shape({
                label: Yup.string().required(t('name')),
                value: Yup.string().required(t('name')),
              }),
              jobPositions: Yup.array().of(
                Yup.object().shape({
                  place: Yup.object().shape({
                    address: Yup.string().required(t('place')),
                  }),
                  date: Yup.number()
                    .typeError(t('date.type'))
                    .required(t('date.required'))
                    .min(new Date(Date.now() - 864e5), t('date.minDate'))
                    .test('date', t('jobdatevalidation'), (value, fields) => {
                      const jobDate = fields.parent.date;
                      const eventExpirationDate = parseInt(
                        moment(fields.from[2].value.eventExpirationDate).format(
                          'x',
                        ),
                      );
                      return !(
                        eventExpirationDate && eventExpirationDate > jobDate
                      );
                    }),
                  start: Yup.date()
                    .test('start', t('startTime.addJob'), (value, fields) => {
                      if (!fields.parent.date) {
                        return false;
                      } else {
                        return true;
                      }
                    })
                    .required(t('startTime.required'))
                    .test('start', t('startTime.duration'), (value, fields) => {
                      return (
                        manageStartEndTime(
                          moment(value).format('x') * 1,
                          fields.parent.date,
                        ) > new Date().valueOf()
                      );
                    }),
                  end: Yup.date()
                    .required(t('endTime.required'))
                    .test(
                      'same',
                      t('startendtimesameerror'),
                      (value, fields) => {
                        let startDate = parseInt(
                          moment(fields.parent.start).format('x'),
                        );
                        let endDate = parseInt(
                          moment(fields.parent.end).format('x'),
                        );
                        if (
                          moment(startDate).format('HH:mm') ===
                          moment(endDate).format('HH:mm')
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      },
                    )
                    .test('end', t('endTime.duration'), (value, fields) => {
                      const hoursDiff = getTimeDifferent(
                        fields.parent.start,
                        fields.parent.end,
                      );
                      return parseFloat(hoursDiff.toFixed(2)) <= 12;
                    }),
                }),
              ),
            }),
          ),
        })}
        onSubmit={async (values, actions) => {
          setCreatingRequest(true);
          actions.setSubmitting(true);

          try {
            const newJobs = values.jobs.map((job) => {
              const staffTypeId = staffTypes.find(
                (s) => s.name === job.jobName.value,
              )._id;
              const newJobPositions = job.jobPositions.map((position) => {
                const endTime =
                  parseInt(moment(position.end).format('x')) <
                  parseInt(moment(position.start).format('x'))
                    ? parseInt(moment(position.end).format('x')) + 86400000
                    : parseInt(moment(position.end).format('x'));
                const travelcost =
                  parseFloat(Number(position?.jobTravelCost).toFixed(2)) || 0;
                const price =
                  parseFloat(Number(position?.price).toFixed(2)) || 0;
                const hourlyRate =
                  parseFloat(Number(position?.hourlyRate).toFixed(2)) || 0;
                const employeeDataJobPrice =
                  parseFloat(
                    Number(position?.employerData?.jobPrice).toFixed(2),
                  ) || 0;
                const employeeDataJobTravelCost =
                  parseFloat(
                    Number(position?.employerData?.jobTravelCost).toFixed(2),
                  ) || 0;
                return {
                  ...position,
                  employerData: {
                    ...position?.employerData,
                    jobPrice: employeeDataJobPrice,
                    jobTravelCost: employeeDataJobTravelCost
                      ? employeeDataJobTravelCost
                      : position?.jobTravelCost,
                    jobTotalCost:
                      employeeDataJobPrice +
                      (employeeDataJobTravelCost
                        ? employeeDataJobTravelCost
                        : travelcost || 0),
                  },
                  jobPlace: position.place.value,
                  jobPlaceAddress: position.place,
                  jobDate: position.date,
                  jobStart: manageStartEndTime(position.start, position.date),
                  jobEnd: manageStartEndTime(endTime, position.date),
                  jobPrice: price,
                  price: price,
                  jobTravelCost: travelcost,
                  jobTotalCost: price + travelcost,
                  hourlyRate: hourlyRate,
                  showAsTeaser: false,
                };
              });
              return {
                ...job,
                jobId: job.id || '',
                jobName: job.jobName.value,
                staffTypeId: staffTypeId,
                jobPositionId:
                  staffTypes?.find((s) => s.name === job.jobName.value)?._id ||
                  '',
                jobBonus: job.jobBonus.value,
                jobPositions: newJobPositions,
                eventExpirationDate: values.eventExpirationDate.valueOf(),
              };
            });
            if (!paymentMethod) {
              setShowFeeModal(true);
              setCreatingRequest(false);
              actions.setSubmitting(true);
              return;
            }

            if (!user.data?.employer?.billingAddress) {
              setPaymentMethod(null);
              setCreatingRequest(false);
              actions.setSubmitting(true);
              toast.error(t('billingaddressdetails'));
              return;
            }

            const result = await dispatch(
              createEvent({
                values: {
                  eventName: values.eventName,
                  eventExpirationDate: values.eventExpirationDate.valueOf(),
                  createdJobs: newJobs,
                  user: user.data._id,
                  companyName: user.data.employer
                    ? user.data.employer.companyName
                    : '',
                  companyId: user.data.employer ? user.data.employer._id : '',
                  paymentMethodId: paymentMethod,
                },
                callback: () => {
                  setCreatingRequest(false);
                  toast.success(t('eventcreated'));
                  actions.setSubmitting(true);
                  history.push(`/${labelRoles.employer}/request-overview`);
                },
              }),
            );

            if (result && !result.success) {
              setPaymentMethod(null);
              setCreatingRequest(false);
              actions.setSubmitting(false);
            }
          } catch (e) {
            setCreatingRequest(false);
            actions.setSubmitting(true);
            handleError(e);
          }
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          isSubmitting,
          validateForm,
        }) => (
          <Form className="form">
            <section className="content-section">
              <Head title={t('title.newRequest')} />
              <Container>
                <div className="position-relative">
                  {creatingRequest && <Loader />}

                  {/* New request */}
                  <div className="box-wrapper form-box-wrapper new-request-block">
                    <h3>{t('newRequestOrg.requestLabel')}</h3>
                    <Row>
                      <Col md="12" xl="6">
                        <Row>
                          <Col>
                            <Row>
                              <Col sm="6" md="6" xl="5">
                                <Input
                                  type="text"
                                  label={`${t('requestOverview.eventName')} *`}
                                  placeholder={t('eventNameplaceholder')}
                                  name="eventName"
                                  value={values.eventName}
                                  errors={errors.eventName}
                                  touched={touched.eventName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>
                              <Col sm="6" md="6" xl="6">
                                <div
                                  className={`${'form-group date-picker-group'} ${
                                    touched.eventExpirationDate &&
                                    errors.eventExpirationDate &&
                                    'form-group-errors'
                                  }`}>
                                  <label className="form-label">
                                    {`${t('requestOverview.expirationDate')} *`}
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 100, hide: 400 }}
                                      overlay={
                                        <Popover>
                                          <Popover.Body>
                                            <h5>
                                              {t(
                                                'requestOverview.expirationDate',
                                              )}
                                            </h5>
                                            <p>{t('expirydatetooltip')}</p>
                                          </Popover.Body>
                                        </Popover>
                                      }>
                                      <svg
                                        width="22"
                                        height="23"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="popover-icon">
                                        <g
                                          transform="translate(0 .5)"
                                          fill="none"
                                          fillRule="evenodd">
                                          <circle
                                            fill="#41A9C7"
                                            cx="11"
                                            cy="11"
                                            r="11"
                                          />
                                          <path
                                            d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                                            fill="#FFF"
                                            fillRule="nonzero"
                                          />
                                        </g>
                                      </svg>
                                    </OverlayTrigger>
                                  </label>
                                  <DatePicker
                                    locale={currentlanguage}
                                    minDate={moment().toDate()}
                                    selected={values.eventExpirationDate}
                                    onChange={(date) =>
                                      setFieldValue('eventExpirationDate', date)
                                    }
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                    name="eventExpirationDate"
                                    onBlur={handleBlur}
                                    dateFormat="dd.MM.yyyy"
                                    calendarStartDay={1}
                                    className="form-control"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />
                                  {touched.eventExpirationDate &&
                                    errors.eventExpirationDate && (
                                      <div className="form-errors">
                                        {errors.eventExpirationDate}
                                      </div>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  {/* Jobs List */}
                  <FieldArray
                    name="jobs"
                    render={(jobArrayHelpers) => {
                      const handleJobFieldChange = ({ id, name, value }) => {
                        const newValues = values.jobs.map((v, vid) => {
                          if (vid === id) {
                            return {
                              ...v,
                              [name]: value,
                            };
                          } else {
                            return { ...v };
                          }
                        });
                        setFieldValue('jobs', newValues);
                      };
                      return (
                        <>
                          {values.jobs && values.jobs.length
                            ? values.jobs.map((job, index) => (
                                <div
                                  className="box-wrapper form-box-wrapper job-list"
                                  key={`job-${index}`}>
                                  <div className="job-list__header">
                                    <h3>
                                      {/*{t('requestOverview.modal.job')} 0*/}
                                      {t('Joboffer')} 0{index + 1}
                                    </h3>
                                    <Row>
                                      <Col sm="4" xl="3">
                                        <CustomSelect
                                          // name="jobName"
                                          name={`jobs.${index}.jobName`}
                                          // label={`${t(
                                          //   'requestOverview.modal.job',
                                          // )} *`}
                                          label={`${t(
                                            'personal_requirements',
                                          )} *`}
                                          options={jobPositions}
                                          isLoading={jobLoading}
                                          handleChange={(value) => {
                                            // setFieldValue('jobName', value);
                                            handleJobFieldChange({
                                              id: index,
                                              name: 'jobName',
                                              value: value,
                                            });
                                          }}
                                          onBlur={(event) => {
                                            setFieldTouched(
                                              `jobs.${index}.jobName`,
                                              true,
                                            );
                                          }}
                                          touched={
                                            touched.jobs &&
                                            touched.jobs[index] &&
                                            touched.jobs[index].jobName
                                          }
                                          errors={
                                            touched.jobs &&
                                            touched.jobs[index] &&
                                            touched.jobs[index].jobName &&
                                            errors.jobs &&
                                            errors.jobs[index] &&
                                            errors.jobs[index].jobName
                                              ? errors.jobs[index].jobName.label
                                              : ''
                                          }
                                          value={
                                            values.jobs &&
                                            values.jobs[index] &&
                                            values.jobs[index].jobName
                                          }
                                          classNamePrefix="job-select c-select"
                                        />
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col sm="6" xl="5">
                                        <label className="form-label text-left">
                                          {t('requestOverview.description')}
                                        </label>
                                        <ReactQuill
                                          theme="snow"
                                          modules={{ toolbar: modules }}
                                          value={
                                            values.jobs &&
                                            values.jobs[index] &&
                                            values.jobs[index].jobDescription
                                          }
                                          onChange={(e) => {
                                            handleJobFieldChange({
                                              id: index,
                                              name: 'jobDescription',
                                              value: e,
                                            });
                                          }}
                                          placeholder={t(
                                            'requestOverview.description.placeholder',
                                          )}
                                          placeholder={t(
                                            'requestOverview.description.placeholder',
                                          )}
                                        />
                                      </Col>
                                      <Col sm="6" xl="5">
                                        <label className="form-label text-left">
                                          {t(
                                            'requestOverview.staffRequirement',
                                          )}
                                        </label>
                                        <ReactQuill
                                          theme="snow"
                                          modules={{ toolbar: modules }}
                                          value={
                                            values.jobs &&
                                            values.jobs[index] &&
                                            values.jobs[index].jobRequirements
                                          }
                                          onChange={(e) => {
                                            handleJobFieldChange({
                                              id: index,
                                              name: 'jobRequirements',
                                              value: e,
                                            });
                                          }}
                                          placeholder={t(
                                            'requestOverview.staffRequirement.placeholder',
                                          )}
                                        />
                                      </Col>

                                      <Col sm="4" xl="2">
                                        <CustomSelect
                                          label={t('requestOverview.bonus')}
                                          name={`jobs.${index}.jobBonus`}
                                          className="bonus-select custom-select"
                                          options={bonusOptions}
                                          classNamePrefix="c-select"
                                          tooltipTitle={t(
                                            'requestOverview.bonus',
                                          )}
                                          tooltipText={t('bonustooltiptext')}
                                          handleChange={(value) => {
                                            handleJobFieldChange({
                                              id: index,
                                              name: 'jobBonus',
                                              value: value,
                                            });
                                          }}
                                          touched={
                                            touched.jobs &&
                                            touched.jobs[index] &&
                                            touched.jobs[index].jobBonus
                                          }
                                          errors={
                                            touched.jobs &&
                                            touched.jobs[index] &&
                                            touched.jobs[index].jobBonus &&
                                            errors.jobs &&
                                            errors.jobs[index] &&
                                            errors.jobs[index].jobBonus
                                              ? errors.jobs[index].jobBonus
                                                  .label
                                              : ''
                                          }
                                          value={
                                            values.jobs &&
                                            values.jobs[index] &&
                                            values.jobs[index].jobBonus
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </div>

                                  <CreateJobPosition
                                    values={values}
                                    places={places}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    index={index}
                                    jobName={values.jobs[index].jobName.value}
                                    jobBonus={values.jobs[index].jobBonus.value}
                                    setFieldTouched={setFieldTouched}
                                    validateForm={validateForm}
                                  />
                                </div>
                              ))
                            : null}

                          {/* New Jobs */}
                          <div className="box-wrapper form-box-wrapper new-job-block">
                            <h3>{t('newRequestOrg.newJobLabel')}</h3>
                            <Row>
                              <Col className="text-right">
                                <a
                                  href="https://google.com"
                                  className="decorated-link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    jobArrayHelpers.push({
                                      ...initialJob,
                                    });
                                    // e.preventDefault();
                                    // setJobNum([...jobNum, true]);
                                  }}>
                                  {t('newRequestOrg.addJobLink')}
                                </a>
                              </Col>
                            </Row>
                          </div>
                        </>
                      );
                    }}
                  />

                  {/* New Jobs */}
                  <div className="form-action-wrapper justify-content-end">
                    <Button
                      type="button"
                      label={t('requestOverview.modal.cancel')}
                      className="btn btn--outline"
                      onClick={() =>
                        history.push(`/${labelRoles.employer}/request-overview`)
                      }
                    />
                    <Button
                      disabled={isSubmitting}
                      // type="submit"
                      type="button"
                      label={t('newRequestOrg.btnRequest')}
                      className="btn primary-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        const checkErrors = () => {
                          if (errors.eventName) {
                            scrollToElement('eventName');
                          } else if (errors.eventExpirationDate) {
                            scrollToElement('eventExpirationDate');
                          } else if (errors.jobs) {
                            let jobError = '';
                            let isName = true;
                            for (
                              let index = 0;
                              index < errors?.jobs?.length;
                              index++
                            ) {
                              const job = errors?.jobs?.[index];
                              if (job) {
                                if (job.jobName) {
                                  jobError = `jobs.${index}.jobName`;
                                  isName = false;
                                } else if (job.jobPositions) {
                                  for (
                                    let poIndex = 0;
                                    poIndex < job?.jobPositions?.length;
                                    poIndex++
                                  ) {
                                    const position =
                                      job?.jobPositions?.[poIndex];
                                    if (position) {
                                      const fields = Object.keys(position);
                                      if (fields?.includes('place')) {
                                        setFieldTouched(
                                          `jobs.${index}.jobPositions.${poIndex}.place.address`,
                                          true,
                                        );
                                      }
                                      if (fields.length > 0) {
                                        jobError = `jobs.${index}.jobPositions.${poIndex}.${fields[0]}`;
                                      }
                                      if (jobError !== '') {
                                        break;
                                      }
                                    }
                                  }
                                }
                              }
                              if (jobError !== '') {
                                break;
                              }
                            }
                            if (jobError !== '') {
                              setFieldTouched(jobError, true);
                              scrollToElement(jobError, isName);
                            }
                          }
                        };
                        setTimeout(() => {
                          checkErrors();
                          if (
                            errors &&
                            Object.keys(errors).length === 0 &&
                            !paymentMethod
                          ) {
                            setShowFeeModal(true);
                          }
                        }, 200);
                      }}
                    />
                  </div>
                </div>
              </Container>
            </section>
            {showFeeModal && (
              <EventFeePayment
                handleSubmit={handleSubmit}
                showFeeModal={showFeeModal}
                setShowFeeModal={setShowFeeModal}
                setPaymentMethod={setPaymentMethod}
                fee={fee}
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewRequest;
