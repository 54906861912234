import React, { useEffect, useState } from 'react';
import apiv2 from '../utils/apiv2';
import Head from '../components/Head';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { t } from 'i18next';
import ConfirmJobHoursForm from '../components/JobPosition/ConfirmJobHoursForm';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { labelRoles } from '../utils/constants';

const ConfirmJobHours = (props) => {
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState({});
  const [jobData, setJobData] = useState({});
  let history = useHistory();
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);

  async function fetchJobPosition() {
    setLoading(true);
    try {
      let response = await apiv2.get(`/job/${props.match.params.id}`);
      const jobs = response.data.data.jobs[0];

      setEventData(response.data.data.jobs[0]);
      const Jobposition = jobs?.jobPositions?.find(
        (pos) => pos?._id === props.match?.params?.id,
      );
      if (Jobposition.jobStart > moment().valueOf()) {
        history.push({
          pathname: `/${labelRoles.employee}/job-details/${props.match.params.id}/${props.match.params.eventID}`,
        });
      }

      setJobData(jobs);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchJobPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.match?.params?.eventID, props?.match?.params?.id]);

  return (
    <>
      <section className="content-section">
        <Head title={`${t('jobdetails')} | Superstaff`} />
        <Container>
          <div className="box-wrapper">
            {loading ? (
              <div className="d-flex align-items-center justify-content-center py-4">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">{t('event.loading')}</span>
                </Spinner>
              </div>
            ) : (
              <>
                <h2 className="text-center">{t('workinghours')}</h2>
                {eventData && (
                  <>
                    <Row>
                      <Col lg={12} sm={12} className="border-bottom mb-3">
                        <label className="text-uppercase">
                          <b>{t('event.name.staff')}</b>
                        </label>
                        <h3>{eventData?.eventName}</h3>
                      </Col>
                      <Col lg={12} sm={12} className="border-bottom mb-3">
                        <label className="text-uppercase">
                          <b>{t('requestOverview.modal.job')}</b>
                        </label>
                        <h3>{jobData?.jobName}</h3>
                      </Col>
                      <Col lg={12} sm={12} className="border-bottom pb-2 mb-3">
                        <ConfirmJobHoursForm
                          setShowConfirmHoursModal={() => {
                            fetchJobPosition();
                          }}
                          setEvent={setEventData}
                          event={eventData}
                          job={jobData}
                          position={jobData?.jobPositions?.find(
                            (pos) => pos?._id === props.match?.params?.id,
                          )}
                          forEmployerSign
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </div>
        </Container>
      </section>
    </>
  );
};

export default ConfirmJobHours;
